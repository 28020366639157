import * as React from 'react';
import './insights.css';
import {API_URLS, APP_URLS} from "../../util/urls";
import {useMatch, useNavigate} from "react-router-dom";
import {useAppContext} from "../../util/context";
import {TopNavbar} from "../../components/navbar/navbar";
import LoadingSplash from "../../components/loading-splash/loading-splash";
import {WorkflowHandlers} from "../workflows/workflows";
import {WorkflowInstance} from "../workflows/common";
import {logout} from "../../util/auth";
import {Container, Form, Row, Col, Badge, Button, Tab, Tabs} from 'react-bootstrap';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, PieChart, Pie, Tooltip, Cell, Legend } from "recharts";

const PieChartComponent = ({ dataDict, showLegend, title }: { dataDict: any, showLegend?: boolean, title?: string }) => {

  // Transform the dictionary into an array of objects
  const data = Object.entries(dataDict).map(([name, value]) => ({
    name,
    value,
  }));

  // Define an array of colors for the chart slices
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#A28CFE",
    "#FF6584",
    "#39D3A1",
    "#FC8EAC",
  ];

  return (
      <div className={'insights-pie-chart insights-chart'}>
          {title && <h5>{title}</h5>}
    <PieChart width={500} height={330}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={90}
        fill="#8884d8"
        label
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
      </div>
  );
};

const BarPlot = ({ dataDict, showLegend, title }: { dataDict: any, showLegend?: boolean, title?: string }) => {
  // Transform the dictionary into an array of objects
  const data = Object.entries(dataDict).map(([key, value]) => ({
    key,
    value,
  }));

  return (
      <div className={'insights-bar-chart insights-chart'}>
          {title && <h5>{title}</h5>}
        <BarChart
          width={500}
          height={330}
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="key" type={'category'} />
          <YAxis />
          <Tooltip />
          {showLegend && <Legend />}
          <Bar dataKey="value" fill="#82ca9d" />
        </BarChart>

      </div>
  );
};


function WorkflowInsightsPage({workflowName}: {workflowName: string}) {
    const navigate = useNavigate();
    const appContext = useAppContext();

    const [loading, setLoading] = React.useState<boolean>(true);
    const [insightsData, setInsightsData] = React.useState({});

    React.useEffect(() => {
        // Refresh metadata
        fetch(API_URLS.getWorkflowInsights, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({workflowName: workflowName})
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            setLoading(false)

            if (status === 200) {
                setInsightsData(data)
            }
            else if (status === 401) {
                logout(appContext)
                navigate(APP_URLS.login)
            }

            return {status, data}
        });
    }, [])

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[workflowName];

    // Loading
    if (loading) {
        return <LoadingSplash/>
    }

    return (<React.Fragment>
            {/* Content */}
            <TopNavbar/>
            <div className={'workflow-title'}>
                Insights – {workflowHandler.displayName}
            </div>
            <Container fluid className={'insights-container'}>
                <Container>
                    <br/>
                    <Tabs
                        defaultActiveKey="overview"
                        id="overview-tab"
                        className="mb-3"
                    >
                        <Tab eventKey="overview" title="Overview">
                            <Row>
                                <Col>
                                    {/* @ts-ignore */}
                                    <BarPlot dataDict={insightsData['stepCompletionDates']} title={'Steps Completed by Week'} />
                                </Col>
                                <Col>
                                    {/* @ts-ignore */}
                                    <BarPlot dataDict={insightsData['completionDatesCumulative']} title={'Total Workflows Finished'} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {/* @ts-ignore */}
                                    <PieChartComponent dataDict={insightsData['currentStepCounts']} title={"Current Step"} />
                                </Col>
                                <Col>
                                    {/* @ts-ignore */}
                                    <BarPlot dataDict={insightsData['flagRatesByStep']} title={"Flag Rate"} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {/* @ts-ignore */}
                                    <PieChartComponent dataDict={insightsData['archivedStepCounts']} title={"Archived at Step"} />
                                </Col>
                                <Col>
                                    {/* @ts-ignore */}

                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="people" title="People">
                            {/* @ts-ignore */}
                            {Object.values(insightsData['peopleStats']).map((personStats: any) => {
                                return <div className={'person-stats'}>
                                    <h5>{personStats['name']}</h5>
                                    <Row>
                                        <Col>
                                            {/* @ts-ignore */}
                                            <PieChartComponent dataDict={personStats['completionSteps']} title={"Steps Completed"} />
                                        </Col>
                                        <Col>

                                        </Col>
                                    </Row>
                                </div>
                            })

                            }
                        </Tab>
                    </Tabs>
                </Container>
            </Container>

        </React.Fragment>
    );
}


export function InsightsPageHandler() {
    // Get URL params
    const matchWithWorkflow = useMatch(`${APP_URLS.workflowsInsights}/:workflowName`);
    const matchBase = useMatch(`${APP_URLS.workflowsInsights}`);
    const match =  matchWithWorkflow || matchBase

    //@ts-ignore
    const workflowName = match?.params.workflowName || "";

    if (workflowName) {
        return <WorkflowInsightsPage workflowName={workflowName}/>
    }

    return <></>
}
